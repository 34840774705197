import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { Navigate } from 'react-router-dom';
import { Box, Container, Typography } from '@mui/material';
import UserManagement from './UserManagement';

const AdminDashboard: React.FC = () => {
    const { user } = useAuth();

    // Redirect if user is not admin or super_admin
    if (!user || (user.role !== 'admin' && user.role !== 'super_admin')) {
        return <Navigate to="/" replace />;
    }

    return (
        <Container maxWidth="lg">
            <Box sx={{ mt: 4, mb: 4 }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Admin Dashboard
                </Typography>
                <UserManagement />
            </Box>
        </Container>
    );
};

export default AdminDashboard; 
import React, { useState, useContext, useEffect } from 'react';
import { validateRequirements, rewriteRequirements } from '../services/aiService';
import { Link, useOutletContext } from 'react-router-dom';
import logo from './veloai-logo.png';
import TextArea from './TextArea';
import { GlobalContext } from './shared/GlobalContext';
import { TreeNode } from '../services/fileSystemService';

interface ValidationResult {
  feedback: any;
}

interface NewRewrittenRequirements {
  feedback: any;
}

interface OutletContextType {
  fileSystem: any;
  selectedFile: TreeNode | null;
}

function ValidationResultsDisplay({ results, newrequirements }: { results: ValidationResult, newrequirements: NewRewrittenRequirements }) {
  const [activeCategories, setActiveCategories] = useState<string[]>(['final']);
  let feedback: any;
  let feedbackrewritten: any;

  try {
    feedback = typeof results === 'string' ? JSON.parse(results) : results;
    feedbackrewritten = typeof newrequirements === 'string' ? JSON.parse(newrequirements) : newrequirements;
  } catch (error) {
    console.error('Error parsing feedback:', error);
    feedback = {};
  }

  return (
    <div className="backdrop-blur-sm bg-velo-blue-900/10 rounded-2xl border border-velo-blue-500/20 overflow-hidden shadow-xl">
      <div className="px-8 py-6">
        <h2 className="text-2xl font-bold text-white mb-6">Validation Results</h2>

        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-velo-blue-500/20">
            <thead>
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-velo-orange-500/80 uppercase tracking-wider">
                  Requirement ID
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-velo-orange-500/80 uppercase tracking-wider">
                  Status
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-velo-orange-500/80 uppercase tracking-wider">
                  Issues
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-velo-orange-500/80 uppercase tracking-wider">
                  Recommendations
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-velo-blue-500/20">
              {feedback?.FinalReport?.ValidatedRequirements.map((req: any, index: number) => (
                <tr key={index} className={index % 2 === 0 ? 'bg-black/20' : 'bg-black/10'}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                    {req.RequirementID}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    <span className={`px-2 py-1 rounded-full text-xs ${req.Status === 'Validated' ? 'bg-green-500/20 text-green-400' :
                      req.Status === 'Validated with Recommendations' ? 'bg-yellow-500/20 text-yellow-400' :
                        req.Status === 'Incomplete' ? 'bg-red-500/20 text-red-400' :
                          'bg-yellow-500/20 text-yellow-400'
                      }`}>
                      {req.Status}
                    </span>
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-300">
                    <ul className="list-disc list-inside">
                      {req.Issues.map((issue: string, idx: number) => (
                        <li key={idx}>{issue}</li>
                      ))}
                    </ul>
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-300">
                    <ul className="list-disc list-inside">
                      {req.Recommendations.map((rec: string, idx: number) => (
                        <li key={idx}>{rec}</li>
                      ))}
                    </ul>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="mt-6 bg-black/20 border border-velo-blue-500/20 rounded-lg p-4">
          <h3 className="text-lg font-medium text-velo-orange-500/80 mb-3">Summary</h3>
          <p className="text-gray-300">{feedback?.FinalReport?.Summary}</p>
        </div>
      </div>
    </div>
  );
}

function RequirementsValidator() {
  const { contextData } = useContext(GlobalContext);
  const { fileSystem, selectedFile } = useOutletContext<OutletContextType>();
  const [requirements, setRequirements] = useState('');
  const [validationResults, setValidationResults] = useState<ValidationResult | null>(null);
  const [rewrittenRequirements, setRewrittenRequirements] = useState<NewRewrittenRequirements | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [savedFileName, setSavedFileName] = useState('');
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  useEffect(() => {
    if (selectedFile && selectedFile.type === 'file' && selectedFile.content) {
      try {
        const content = selectedFile.content;
        if (content.requirements) {
          setRequirements(content.requirements);
        }
        if (content.validationResults) {
          setValidationResults(content.validationResults);
        }
        if (content.rewrittenRequirements) {
          setRewrittenRequirements(content.rewrittenRequirements);
        }
        setSavedFileName(selectedFile.name);
      } catch (error) {
        console.error('Error loading file:', error);
        setError('Error loading the selected file');
      }
    }
  }, [selectedFile, refreshTrigger]);

  const saveToFileSystem = async (results: ValidationResult, rewritten: NewRewrittenRequirements) => {
    try {
      const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
      const folderName = savedFileName || `validation-${timestamp}`;
      
      // Create main folder for this validation
      fileSystem.createFolder(['Requirements', folderName]);
      
      // Save the main validation results
      const mainContent = {
        requirements,
        validationResults: results,
        rewrittenRequirements: rewritten,
        timestamp: new Date().toISOString(),
      };
      
      fileSystem.saveFile(['Requirements', folderName, 'validation-results.json'], mainContent, 'requirements');

      // Extract and create tree structure for rewritten requirements
      const rewrittenReqs = typeof rewritten === 'string' ? JSON.parse(rewritten) : rewritten;
      
      if (rewrittenReqs?.requirements) {
        rewrittenReqs.requirements.forEach((req: any) => {
          // Create requirement folder with just the ID
          const reqPath = ['Requirements', folderName, `${req.id}`];
          fileSystem.createFolder(reqPath);
          
          // Create Description folder and save content
          const descPath = [...reqPath, 'Description'];
          fileSystem.createFolder(descPath);
          fileSystem.saveFile(
            [...descPath, 'content'],
            req.description,
            'text'
          );

          // Create Acceptance Criteria folder and save content
          const acPath = [...reqPath, 'Acceptance Criteria'];
          fileSystem.createFolder(acPath);
          
          req.acceptanceCriteria.forEach((ac: any, index: number) => {
            fileSystem.saveFile(
              [...acPath, `${index + 1}`],
              ac.criterion,
              'text'
            );
          });
        });
      }

      setSavedFileName(folderName);
      setRefreshTrigger(prev => prev + 1);
    } catch (error) {
      console.error('Error saving to file system:', error);
      setError('Error saving validation results');
    }
  };

  const handleValidation = async () => {
    setIsLoading(true);
    try {
      let contextString = '';
      
      if (contextData.businessContext) {
        contextString += `Business Context:\n${contextData.businessContext}\n\n`;
      }
      
      if (contextData.technicalContext) {
        contextString += `Technical Context:\n${contextData.technicalContext}\n\n`;
      }
      
      if (contextData.constraints) {
        contextString += `Constraints:\n${contextData.constraints}\n\n`;
      }
      
      if (contextData.assumptions) {
        contextString += `Assumptions:\n${contextData.assumptions}\n\n`;
      }
      
      if (Object.keys(contextData.diagrams).length > 0) {
        contextString += 'Diagrams Content:\n';
        Object.entries(contextData.diagrams).forEach(([name, content]) => {
          contextString += `${name}:\n${content}\n`;
        });
        contextString += '\n';
      }
      

      if (Object.keys(contextData.documents).length > 0) {
        contextString += 'Documents Content:\n';
        Object.entries(contextData.documents).forEach(([name, content]) => {
          contextString += `${name}:\n${content}\n`;
        });
        contextString += '\n';
      }

      const results = await validateRequirements(requirements, contextString);
      const newrequirements = await rewriteRequirements(requirements, results);
      setValidationResults(results);
      setRewrittenRequirements(newrequirements);


      saveToFileSystem(results, newrequirements);

      setError(null);
    } catch (error) {
      console.error('Error during validation:', error);
      setError('An error occurred during validation');
    } finally {
      setIsLoading(false);
    }
  };


  const handleNewValidation = () => {
    setRequirements('');
    setValidationResults(null);
    setRewrittenRequirements(null);
    setSavedFileName('');
    setError(null);
  };


  return (
    <div className="min-h-screen bg-gradient-to-br from-velo-blue-900/90 via-black to-black">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Header Section */}
        <div className="flex items-center justify-between mb-16">
          <div className="flex items-center space-x-6">
            <img
              src={logo}
              alt="VeloAI Logo"
              className="h-14 w-auto filter drop-shadow-glow"
            />
            <div>
              <div className="text-velo-orange-500/80 text-sm font-medium mb-1">Testing Tools</div>
              <h1 className="text-4xl font-bold text-white tracking-tight">
                Requirements Validator
              </h1>
            </div>
          </div>

          <div className="flex items-center space-x-4">
            <button
              onClick={handleNewValidation}
              className="group flex items-center space-x-2 px-4 py-2 rounded-lg text-gray-300 hover:text-white hover:bg-neutral-800/50 transition-all duration-200"
            >
              <span>New Validation</span>
              <span className="transform group-hover:translate-x-0.5 transition-transform duration-150">+</span>
            </button>
            <Link 
              to="/" 
              className="group flex items-center space-x-2 text-sm text-gray-400 hover:text-white transition-colors duration-200"
            >
              <span>Return to Dashboard</span>
              <span className="transform group-hover:translate-x-0.5 transition-transform duration-150">→</span>
            </Link>
          </div>

        </div>

        {/* Main Content */}
        <div className="grid grid-cols-1 gap-8">
          {/* Requirements Input */}
          <div className="backdrop-blur-sm bg-velo-blue-900/10 rounded-2xl border border-velo-blue-500/20 overflow-hidden shadow-xl">
            <div className="px-8 py-6">
              <TextArea
                label="Requirements"
                value={requirements}
                onChange={(e) => setRequirements(e.target.value)}
                placeholder="Enter your requirements here..."
                rows={10}
              />
            </div>
          </div>

          {/* Validate Button */}
          <div className="flex justify-center">
            <button
              onClick={handleValidation}
              disabled={isLoading || !requirements.trim()}

              className={`group relative px-8 py-4 rounded-xl font-medium text-base
                ${isLoading 
                  ? 'bg-velo-blue-900/50 cursor-not-allowed' 
                  : 'bg-gradient-to-r from-velo-orange-500 to-velo-orange-600 hover:to-velo-orange-500'
                }
                text-white transition-all duration-300 shadow-lg hover:shadow-velo-orange-500/20
                transform hover:-translate-y-0.5
              `}
            >
              <span className="flex items-center space-x-2">
                {isLoading ? (
                  <>
                    <span className="opacity-70">Validating Requirements</span>
                    <span className="animate-pulse">...</span>
                  </>
                ) : (
                  <>
                    <span>Validate Requirements</span>
                    <span className="transform group-hover:translate-x-0.5 transition-transform duration-150">
                      →
                    </span>
                  </>
                )}
              </span>
            </button>
          </div>

          {/* Error Display */}
          {error && (
            <div className="text-red-500 text-center">
              {error}
            </div>
          )}

          {/* Validation Results */}
          {validationResults && (
            <ValidationResultsDisplay
              results={validationResults}
              newrequirements={rewrittenRequirements!}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default RequirementsValidator;

const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });
};

import React, { useState, useEffect } from 'react';
import { TreeNode } from '../services/fileSystemService';
import { FaChevronRight, FaChevronDown } from 'react-icons/fa';

interface TreeViewProps {
  data: TreeNode;
  onFileSelect?: (node: TreeNode) => void;
  className?: string;
  refreshTrigger?: number;
  onSelectionChange?: (selectedNodes: TreeNode[]) => void;
  initialSelectedNodes?: Set<string>;
}

interface TreeNodeProps {
  data: TreeNode;
  level: number;
  onFileSelect?: (node: TreeNode) => void;
  onNodeSelect: (node: TreeNode) => void;
  selectedNode: TreeNode | null;
  refreshTrigger: number;
  selectedNodes: Set<string>;
  onNodeCheckChange: (node: TreeNode & { level: number }, checked: boolean) => void;
}

const TreeNodeComponent: React.FC<TreeNodeProps> = ({
  data,
  level,
  onFileSelect,
  onNodeSelect,
  selectedNode,
  refreshTrigger,
  selectedNodes,
  onNodeCheckChange,
}) => {
  // Create a unique ID based on both name and level
  const nodeId = `${data.name}-${level}`;
  
  console.log('Rendering node:', data.name, 'unique id:', nodeId);
  console.log('selectedNodes contains:', Array.from(selectedNodes));

  const [isOpen, setIsOpen] = useState(level < 2);

  useEffect(() => {
    if (level < 2) {
      setIsOpen(true);
    }
  }, [refreshTrigger, level]);

  if (data.name === 'validation-results.json') {
    return null;
  }

  const handleToggle = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (data.type === 'folder') {
      setIsOpen(!isOpen);
    }
  };

  const handleClick = () => {
    onNodeSelect(data);
    if (data.type === 'file' && onFileSelect) {
      onFileSelect(data);
    }
  };

  const handleCheckChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    console.log('CHECKBOX CLICKED');
    const nodeWithLevel = { ...data, level };
    console.log('Node being checked:', nodeWithLevel);
    console.log('Checkbox new state:', e.target.checked);
    onNodeCheckChange(nodeWithLevel, e.target.checked);
  };

  const isSelected = selectedNode?.id === data.id;
  const isContentNode = level > 3 && data.type === 'file';
  const isHeaderNode = level === 2 && data.type === 'folder';
  const isTimestampFolder = level === 3 && data.type === 'folder';
  const isRequirementNode = level === 3 && data.type === 'folder';
  const isDescriptionContent = data.type === 'file' && data.content;
  const isAcceptanceCriteriaItem = data.type === 'file' && /^\d+$/.test(data.name);

  return (
    <div className="select-none text-xs">
      <div
        className={`group flex items-start py-1 transition-colors duration-150 ${
          isSelected ? 'bg-gray-700/30 text-gray-200' : 'hover:bg-black/20'
        } ${
          isRequirementNode 
            ? 'text-orange-400/90 font-medium' 
            : isTimestampFolder
              ? 'text-blue-300/90 font-medium'
              : isHeaderNode
                ? 'text-blue-300/90 font-medium'
                : isContentNode
                  ? 'text-gray-400 text-xs'
                  : 'text-gray-300'
        }`}
        style={{ paddingLeft: `${Math.max(0.5, level * 1.25)}rem` }}
        onClick={handleClick}
      >
        <div className="flex items-center min-w-[20px]">
          {isRequirementNode && (
            <input
              type="checkbox"
              className="mr-2 h-3 w-3 rounded border-gray-600 text-blue-500 focus:ring-blue-500 focus:ring-offset-0 bg-gray-800/50"
              checked={selectedNodes.has(nodeId)}
              onChange={handleCheckChange}
              onClick={(e) => e.stopPropagation()}
            />
          )}
          {data.type === 'folder' && (
            <span
              className="flex-none mt-1 mr-1 w-3 h-3 flex items-center justify-center transition-colors"
              onClick={handleToggle}
            >
              {isOpen ? <FaChevronDown size={10} /> : <FaChevronRight size={10} />}
            </span>
          )}
        </div>
        <span className={`${isContentNode ? 'ml-2' : ''} break-words pr-3 leading-relaxed`}>
          {isDescriptionContent 
            ? data.content
            : isAcceptanceCriteriaItem 
              ? `• ${data.content}`
              : data.name}
        </span>
      </div>
      {isOpen && data.children && (
        <div className={`${!isRequirementNode ? 'border-l border-gray-800' : ''}`}>
          {data.children.map((child, index) => (
            <TreeNodeComponent
              key={`${child.id}-${index}`}
              data={child}
              level={level + 1}
              onFileSelect={onFileSelect}
              onNodeSelect={onNodeSelect}
              selectedNode={selectedNode}
              refreshTrigger={refreshTrigger}
              selectedNodes={new Set(selectedNodes)} // Create a new Set for each child
              onNodeCheckChange={onNodeCheckChange}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const TreeView: React.FC<TreeViewProps> = ({ data, onFileSelect, onSelectionChange }) => {
  const [selectedNode, setSelectedNode] = useState<TreeNode | null>(null);
  const [selectedNodes, setSelectedNodes] = useState<Set<string>>(new Set());
  const [selectedNodeMap, setSelectedNodeMap] = useState<Map<string, TreeNode>>(new Map());
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  const handleNodeSelect = (node: TreeNode) => {
    setSelectedNode(node);
  };

  const handleNodeCheckChange = (node: TreeNode & { level: number }, checked: boolean) => {
    console.log('HANDLE NODE CHECK CHANGE');
    const nodeId = `${node.name}-${node.level}`;
    
    const newSelectedNodes = new Set(selectedNodes);
    const newSelectedNodeMap = new Map(selectedNodeMap);
    
    if (checked) {
      console.log('Adding node:', nodeId);
      newSelectedNodes.add(nodeId);
      newSelectedNodeMap.set(nodeId, node);
    } else {
      console.log('Removing node:', nodeId);
      newSelectedNodes.delete(nodeId);
      newSelectedNodeMap.delete(nodeId);
    }
    
    setSelectedNodes(newSelectedNodes);
    setSelectedNodeMap(newSelectedNodeMap);
    
    // Pass all selected nodes to the parent
    const selectedNodesList = Array.from(newSelectedNodeMap.values());
    console.log('Calling onSelectionChange with all nodes:', selectedNodesList);
    onSelectionChange?.(selectedNodesList);
  };

  return (
    <div className="select-none h-full">
      <TreeNodeComponent
        data={data}
        level={0}
        onFileSelect={onFileSelect}
        onNodeSelect={handleNodeSelect}
        selectedNode={selectedNode}
        refreshTrigger={refreshTrigger}
        selectedNodes={new Set(selectedNodes)}
        onNodeCheckChange={handleNodeCheckChange}
      />
    </div>
  );
};

export default TreeView;

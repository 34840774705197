import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';

const AdminDashboardTile: React.FC = () => {
    const { user } = useAuth();
    const navigate = useNavigate();

    // Only render for admin and super_admin users
    if (!user || (user.role !== 'admin' && user.role !== 'super_admin')) {
        return null;
    }

    return (
        <Box
            onClick={() => navigate('/admin')}
            sx={{
                backgroundColor: '#1a1a1a',
                borderRadius: '8px',
                padding: '20px',
                cursor: 'pointer',
                transition: 'transform 0.2s',
                '&:hover': {
                    transform: 'scale(1.02)',
                },
                display: 'flex',
                flexDirection: 'column',
                gap: '10px'
            }}
        >
            <Typography variant="h6" component="h2" sx={{ color: '#fff' }}>
                ADMIN DASHBOARD
            </Typography>
            <Typography variant="body2" sx={{ color: '#aaa' }}>
                Manage users, credits, and subscriptions
            </Typography>
            <Box
                sx={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    backgroundColor: '#ff4444',
                    color: 'white',
                    padding: '4px 8px',
                    borderRadius: '4px',
                    fontSize: '0.75rem'
                }}
            >
                08
            </Box>
        </Box>
    );
};

export default AdminDashboardTile; 
import React from 'react';
import { Link } from 'react-router-dom';
import logo from './veloai-logo.png';
import AdminDashboardTile from './AdminDashboardTile';

const modules = [
  { 
    id: '01', 
    name: 'Requirements Validator', 
    path: '/requirements-validator', 
    description: 'Automatically verifies and validates project requirements against best practices and standards to ensure clarity and completeness',
    icon: (
      <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
    )
  },
  { 
    id: '02', 
    name: 'Test Scenario Design', 
    path: '/test-scenario-design', 
    description: 'Designs comprehensive test scenarios based on requirements',
    icon: (
      <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
      </svg>
    )
  },
  { 
    id: '03', 
    name: 'Test Case Creation', 
    path: '/test-case-creation', 
    description: 'Creates detailed test cases based on scenarios',
    icon: (
      <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
      </svg>
    )
  },
  { 
    id: '04', 
    name: 'Automation Development', 
    path: '/automation-development', 
    description: 'Develops automated test scripts for efficient testing',
    icon: (
      <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5" />
      </svg>
    )
  },
  { 
    id: '05', 
    name: 'Coverage Optimisation', 
    path: '/coverage-optimisation', 
    description: 'Optimises test coverage to ensure maximum efficiency',
    icon: (
      <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
      </svg>
    )
  },
  { 
    id: '06', 
    name: 'Unit Test Generator', 
    path: '/unit-test-generator', 
    description: 'Generates unit tests for code components',
    icon: (
      <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" />
      </svg>
    )
  },
  { 
    id: '07', 
    name: 'Unit Test Coverage Analyser', 
    path: '/unit-test-coverage-analyzer', 
    description: 'Analyses and reports on unit test coverage',
    icon: (
      <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
      </svg>
    )
  },
];

function LandingPage() {
  return (
    <div className="min-h-screen bg-gradient-to-br from-velo-blue-900/90 via-black to-black">
      {/* Hero Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-20 pb-16">
        <div className="text-center">
        </div>
      </div>

      {/* Features Grid */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-20">
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
          {modules.map((module) => (
            <Link
              key={module.id}
              to={module.path}
              className="group relative bg-black/30 backdrop-blur-xl p-6 rounded-2xl border border-white/5 shadow-2xl transform transition-all duration-300 hover:scale-[1.02] hover:bg-black/40"
            >
              <div className="flex items-center space-x-4 mb-4">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-xl bg-velo-orange-500/10 text-velo-orange-500 group-hover:bg-velo-orange-500/20 transition-colors duration-200">
                    {module.icon}
                  </div>
                </div>
                <div className="flex-1 min-w-0">
                  <span className="absolute inset-0" aria-hidden="true" />
                  <p className="text-sm font-medium text-velo-orange-400">
                    Module {module.id}
                  </p>
                  <h3 className="text-lg font-semibold text-white truncate">
                    {module.name}
                  </h3>
                </div>
              </div>
              <p className="text-gray-400 text-sm">
                {module.description}
              </p>
            </Link>
          ))}

          {/* Admin Dashboard Tile */}
          <div className="sm:col-span-2 lg:col-span-3">
            <AdminDashboardTile />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;

import React from 'react';
import { useAuth } from '../contexts/AuthContext';

const UserCreditsDisplay: React.FC = () => {
    const { user } = useAuth();

    if (!user) return null;

    return (
        <div className="flex items-center space-x-4">
            <div className="flex items-center space-x-2">
                <div className="w-8 h-8 rounded-full bg-white/5 backdrop-blur-lg flex items-center justify-center">
                    <svg className="w-4 h-4 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                    </svg>
                </div>
                <span className="text-gray-300 text-sm font-medium">{user.name}</span>
            </div>
            <div className="flex items-center space-x-2 px-3 py-1.5 bg-white/5 backdrop-blur-lg rounded-lg border border-white/10">
                <svg className="w-4 h-4 text-velo-orange-400" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 100-16 8 8 0 000 16zm-1.95-9H8v2h2.05a3.001 3.001 0 005.9 0H18v-2h-2.05a3.001 3.001 0 00-5.9 0zM12 13a1 1 0 100-2 1 1 0 000 2z" />
                </svg>
                <span className="text-velo-orange-400 text-sm font-semibold tracking-wide">
                    {user.credits} tokens
                </span>
            </div>
        </div>
    );
};

export default UserCreditsDisplay;
import React, { useState, useEffect } from 'react';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Alert,
    Typography,
    IconButton,
    InputAdornment,
} from '@mui/material';
import axiosInstance from '../../config/axios';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

interface User {
    id: string;
    name: string;
    email: string;
    company: string;
    credits: number;
    status: 'active' | 'inactive' | 'suspended';
    subscriptionEnd: string;
    role: 'super_admin' | 'admin' | 'user';
    creditsUsed?: number;
    lastLoginAt?: Date;
    lastActivityAt?: Date;
    companyRole?: string;
}

const UserManagement: React.FC = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [open, setOpen] = useState(false);
    const [error, setError] = useState('');
    const [newUser, setNewUser] = useState({
        name: '',
        email: '',
        password: '',
        company: '',
        subscriptionEnd: '',
        credits: 300,
    });
    const [creditDialog, setCreditDialog] = useState(false);
    const [selectedUser, setSelectedUser] = useState<User | null>(null);
    const [creditAmount, setCreditAmount] = useState<number>(100);

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await axiosInstance.get('/admin/users');
            setUsers(response.data);
        } catch (err) {
            setError('Failed to fetch users');
        }
    };

    const handleCreateUser = async () => {
        try {
            await axiosInstance.post('/admin/users', newUser);
            setOpen(false);
            fetchUsers();
            setNewUser({
                name: '',
                email: '',
                password: '',
                company: '',
                subscriptionEnd: '',
                credits: 300,
            });
        } catch (err) {
            setError('Failed to create user');
        }
    };

    const handleUpdateUserStatus = async (userId: string, status: 'active' | 'inactive') => {
        try {
            await axiosInstance.put(`/admin/users/${userId}`, { status });
            fetchUsers();
        } catch (err) {
            setError('Failed to update user status');
        }
    };

    const handleCreditDialogOpen = (user: User) => {
        setSelectedUser(user);
        setCreditDialog(true);
        setCreditAmount(100);
    };

    const handleCreditDialogClose = () => {
        setCreditDialog(false);
        setSelectedUser(null);
    };

    const handleUpdateCredits = async () => {
        if (!selectedUser) return;

        try {
            const newCredits = selectedUser.credits + creditAmount;
            await axiosInstance.put(`/admin/users/${selectedUser.id}`, {
                credits: newCredits
            });
            await fetchUsers();
            handleCreditDialogClose();
        } catch (err) {
            setError('Failed to update credits');
        }
    };

    return (
        <Box>
            {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}

            <Button
                variant="contained"
                color="primary"
                onClick={() => setOpen(true)}
                sx={{ mb: 2 }}
            >
                Create New User
            </Button>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Company</TableCell>
                            <TableCell>Credits</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Subscription End</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map((user) => (
                            <TableRow key={user.id}>
                                <TableCell>{user.name}</TableCell>
                                <TableCell>{user.email}</TableCell>
                                <TableCell>{user.company}</TableCell>
                                <TableCell>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <Typography>{user.credits}</Typography>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                            onClick={() => handleCreditDialogOpen(user)}
                                            disabled={user.role === 'super_admin'}
                                        >
                                            Manage Credits
                                        </Button>
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Button
                                        variant="outlined"
                                        color={user.status === 'active' ? 'success' : 'error'}
                                        onClick={() => handleUpdateUserStatus(
                                            user.id,
                                            user.status === 'active' ? 'inactive' : 'active'
                                        )}
                                    >
                                        {user.status}
                                    </Button>
                                </TableCell>
                                <TableCell>{new Date(user.subscriptionEnd).toLocaleDateString()}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog open={creditDialog} onClose={handleCreditDialogClose}>
                <DialogTitle>Manage Credits for {selectedUser?.name}</DialogTitle>
                <DialogContent>
                    <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
                        <IconButton
                            onClick={() => setCreditAmount(prev => Math.max(0, prev - 100))}
                            color="primary"
                        >
                            <RemoveIcon />
                        </IconButton>
                        <TextField
                            type="number"
                            label="Credits to Add/Remove"
                            value={creditAmount}
                            onChange={(e) => setCreditAmount(Number(e.target.value))}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        {creditAmount >= 0 ? '+' : '-'}
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <IconButton
                            onClick={() => setCreditAmount(prev => prev + 100)}
                            color="primary"
                        >
                            <AddIcon />
                        </IconButton>
                    </Box>
                    {selectedUser && (
                        <Typography sx={{ mt: 2 }}>
                            New Balance will be: {selectedUser.credits + creditAmount} credits
                        </Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCreditDialogClose}>Cancel</Button>
                    <Button onClick={handleUpdateCredits} color="primary">
                        Update Credits
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Create New User</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Name"
                        fullWidth
                        value={newUser.name}
                        onChange={(e) => setNewUser({ ...newUser, name: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        label="Email"
                        type="email"
                        fullWidth
                        value={newUser.email}
                        onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        label="Password"
                        type="password"
                        fullWidth
                        value={newUser.password}
                        onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        label="Company"
                        fullWidth
                        value={newUser.company}
                        onChange={(e) => setNewUser({ ...newUser, company: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        label="Subscription End Date"
                        type="date"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        value={newUser.subscriptionEnd}
                        onChange={(e) => setNewUser({ ...newUser, subscriptionEnd: e.target.value })}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                    <Button onClick={handleCreateUser} color="primary">Create</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default UserManagement; 
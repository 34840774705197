import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import LandingPage from './components/LandingPage';
import RequirementsValidator from './components/requirementsValidator';
import TestScenarioDesign from './components/TestScenarioDesign';
import TestCaseCreation from './components/TestCaseCreation';
import AutomationDevelopment from './components/AutomationDevelopment';
import CoverageOptimisation from './components/CoverageOptimisation';
import UnitTestGenerator from './components/UnitTestGenerator';
import UnitTestCoverageAnalyzer from './components/UnitTestCoverageAnalyzer';
import GlobalContext from './components/shared/GlobalContext';
import { GlobalContextProvider } from './components/shared/GlobalContext';
import { AuthProvider } from './contexts/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import Login from './pages/Login';
import AdminDashboard from './pages/admin/Dashboard';

function App() {
  return (
    <AuthProvider>
      <GlobalContextProvider>
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/admin" element={
              <ProtectedRoute>
                <AdminDashboard />
              </ProtectedRoute>
            } />
            <Route path="/" element={
              <ProtectedRoute>
                <Layout />
              </ProtectedRoute>
            }>
              <Route index element={<LandingPage />} />
              <Route path="global-context" element={<GlobalContext />} />
              <Route path="requirements-validator" element={<RequirementsValidator />} />
              <Route path="test-scenario-design" element={<TestScenarioDesign />} />
              <Route path="test-case-creation" element={<TestCaseCreation />} />
              <Route path="automation-development" element={<AutomationDevelopment />} />
              <Route path="coverage-optimisation" element={<CoverageOptimisation />} />
              <Route path="unit-test-generator" element={<UnitTestGenerator />} />
              <Route path="unit-test-coverage-analyzer" element={<UnitTestCoverageAnalyzer />} />
            </Route>
          </Routes>
        </Router>
      </GlobalContextProvider>
    </AuthProvider>
  );
}

export default App;
import React, { useState, useContext } from 'react';
import { Link, useOutletContext } from 'react-router-dom';
import { generateTestScenarios, TestScenario } from '../services/aiService';
import logo from './veloai-logo.png';
import TextArea from '../components/TextArea';
import { GlobalContext } from './shared/GlobalContext';
import { TreeNode } from '../services/fileSystemService';

interface OutletContextType {
  fileSystem: any;
  selectedFile: TreeNode | null;
  refreshCounter: number;
  selectedNodes: TreeNode[];
}

const defaultScenario: TestScenario = {
  "Scenario ID": "N/A",
  "Test Scenario": "N/A",
  "Test Level": "N/A",
  "Test Type": "N/A",
  "Test Design Technique": "N/A",
  "Priority": "N/A",
  "Risk": "N/A",
  "Execution Order": "N/A",
  "Requirements ID": []
};

function TestScenarioDesign() {
  const { contextData } = useContext(GlobalContext);
  const { selectedNodes } = useOutletContext<OutletContextType>();
  const [requirements, setRequirements] = useState('');
  const [scenarios, setScenarios] = useState<TestScenario[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [sortBy, setSortBy] = useState<'Requirements ID' | 'Priority' | 'Risk' | 'Execution Order'>('Requirements ID');

  const sortedScenarios = React.useMemo(() => {
    return [...scenarios].sort((a, b) => {
      switch (sortBy) {
        case 'Requirements ID':
          const aReq = a["Requirements ID"][0] || '';
          const bReq = b["Requirements ID"][0] || '';
          return aReq.localeCompare(bReq);
          
        case 'Execution Order':
          return Number(a["Execution Order"]) - Number(b["Execution Order"]);
          
        case 'Priority':
        case 'Risk':
          const priorityOrder = { 'High': 3, 'Medium': 2, 'Low': 1 };
          const aValue = priorityOrder[a[sortBy] as keyof typeof priorityOrder] || 0;
          const bValue = priorityOrder[b[sortBy] as keyof typeof priorityOrder] || 0;
          return bValue - aValue;
          
        default:
          return 0;
      }
    });
  }, [scenarios, sortBy]);

  const handleImportRequirements = () => {
    if (!selectedNodes.length) return;

    const importedText = selectedNodes
      .map(node => {
        // Each requirement folder should have Description and Acceptance Criteria folders
        const description = node.children?.find(child => 
          child.name === 'Description' && child.children?.[0]?.content
        )?.children?.[0]?.content || '';

        const acceptanceCriteria = node.children?.find(child => 
          child.name === 'Acceptance Criteria'
        )?.children?.map(criterion => criterion.content).filter(Boolean).join('\n') || '';

        if (!description && !acceptanceCriteria) return null;

        return `Requirement: ${node.name}\n\nDescription:\n${description}\n\nAcceptance Criteria:\n${acceptanceCriteria}`;
      })
      .filter(Boolean)
      .join('\n\n---\n\n');

    if (importedText) {
      setRequirements(currentRequirements => {
        const currentText = currentRequirements.trim();
        return currentText ? `${currentText}\n\n---\n\n${importedText}` : importedText;
      });
    }
  };

  const handleGenerateScenarios = async () => {
    if (!requirements.trim()) {
      setError('Please provide requirements');
      return;
    }

    setIsLoading(true);
    setError(null);
    try {
      const contextString = `
Business Context:
${contextData.businessContext}

Technical Context:
${contextData.technicalContext}

Constraints:
${contextData.constraints}

Assumptions:
${contextData.assumptions}

Diagrams:
${Object.entries(contextData.diagrams).map(([fileName, content]) => `${fileName}: ${content}`).join('\n')}

Documents:
${Object.entries(contextData.documents).map(([fileName, content]) => `${fileName}: ${content}`).join('\n')}
      `.trim();

      // Extract requirement IDs from the requirements text
      const requirementIds = requirements.split('---')
        .map(req => {
          const match = req.match(/Requirement: (REQ-\d+)/);
          return match ? match[1] : null;
        })
        .filter(Boolean) as string[];

      const result = await generateTestScenarios(requirements, contextString, requirementIds);
      
      if (result && Array.isArray(result.scenarios)) {
        setScenarios(result.scenarios);
      } else {
        setError('Received unexpected data format from the server.');
        setScenarios([defaultScenario]);
      }
    } catch (error) {
      setError('An error occurred while generating scenarios. Please try again.');
      setScenarios([defaultScenario]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-velo-blue-900/90 via-black to-black">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Header Section */}
        <div className="flex items-center justify-between mb-16">
          <div className="flex items-center space-x-6">
            <img 
              src={logo} 
              alt="VeloAI Logo" 
              className="h-14 w-auto filter drop-shadow-glow" 
            />
            <div>
              <div className="text-velo-orange-500/80 text-sm font-medium mb-1">Testing Tools</div>
              <h1 className="text-4xl font-bold text-white tracking-tight">
                Test Scenario Design
              </h1>
            </div>
          </div>
          <Link 
            to="/" 
            className="group flex items-center space-x-2 text-sm text-gray-400 hover:text-white transition-colors duration-200"
          >
            <span>Return to Dashboard</span>
            <span className="transform group-hover:translate-x-0.5 transition-transform duration-150">→</span>
          </Link>
        </div>

        {/* Main Content */}
        <div className="grid grid-cols-1 gap-8">
          {/* Import Button */}
          {selectedNodes.length > 0 && (
            <div className="flex justify-end">
              <button
                onClick={handleImportRequirements}
                className="px-4 py-2 bg-velo-blue-600 text-white rounded-lg hover:bg-velo-blue-500 transition-colors flex items-center space-x-2"
              >
                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
                </svg>
                <span>Import Selected Requirements ({selectedNodes.length})</span>
              </button>
            </div>
          )}

          {/* Requirements Input */}
          <div className="backdrop-blur-sm bg-velo-blue-900/10 rounded-2xl border border-velo-blue-500/20 overflow-hidden shadow-xl">
            <div className="px-8 py-6">
              <TextArea
                label="Requirements"
                value={requirements}
                onChange={(e) => setRequirements(e.target.value)}
                placeholder="Enter your requirements here or import them from the tree view..."
                rows={10}
              />
            </div>
          </div>

          {/* Generate Button */}
          <div className="flex justify-center">
            <button
              onClick={handleGenerateScenarios}
              disabled={isLoading || !requirements.trim()}
              className={`
                group relative px-8 py-4 rounded-xl font-medium text-base
                ${isLoading 
                  ? 'bg-velo-blue-900/50 cursor-not-allowed' 
                  : 'bg-gradient-to-r from-velo-orange-500 to-velo-orange-600 hover:to-velo-orange-500'
                }
                text-white transition-all duration-300 shadow-lg hover:shadow-velo-orange-500/20
                transform hover:-translate-y-0.5
              `}
            >
              <span className="flex items-center space-x-2">
                {isLoading ? (
                  <>
                    <span className="opacity-70">Generating Scenarios</span>
                    <span className="animate-pulse">...</span>
                  </>
                ) : (
                  <>
                    <span>Generate Test Scenarios</span>
                    <span className="transform group-hover:translate-x-0.5 transition-transform duration-150">
                      →
                    </span>
                  </>
                )}
              </span>
            </button>
          </div>

          {/* Error Message */}
          {error && (
            <div className="mt-6 backdrop-blur-sm bg-red-500/10 rounded-xl border border-red-500/20 p-4">
              <p className="text-red-400 text-center text-sm">{error}</p>
            </div>
          )}

          {/* Generated Scenarios */}
          {scenarios.length > 0 && (
            <div className="mt-8 backdrop-blur-sm bg-velo-blue-900/10 rounded-2xl border border-velo-blue-500/20 overflow-hidden shadow-xl">
              <div className="px-8 py-6">
                <div className="flex justify-between items-center mb-6">
                  <h2 className="text-xl font-semibold text-white">Generated Test Scenarios</h2>
                  <div className="flex items-center space-x-4">
                    <button
                      onClick={() => setSortBy('Requirements ID')}
                      className={`px-3 py-1.5 rounded-lg text-sm ${
                        sortBy === 'Requirements ID' ? 'bg-velo-orange-500 text-white' : 'text-gray-400 hover:text-white'
                      }`}
                    >
                      Sort by Requirement
                    </button>
                    <button
                      onClick={() => setSortBy('Priority')}
                      className={`px-3 py-1.5 rounded-lg text-sm ${
                        sortBy === 'Priority' ? 'bg-velo-orange-500 text-white' : 'text-gray-400 hover:text-white'
                      }`}
                    >
                      Sort by Priority
                    </button>
                    <button
                      onClick={() => setSortBy('Risk')}
                      className={`px-3 py-1.5 rounded-lg text-sm ${
                        sortBy === 'Risk' ? 'bg-velo-orange-500 text-white' : 'text-gray-400 hover:text-white'
                      }`}
                    >
                      Sort by Risk
                    </button>
                    <button
                      onClick={() => setSortBy('Execution Order')}
                      className={`px-3 py-1.5 rounded-lg text-sm ${
                        sortBy === 'Execution Order' ? 'bg-velo-orange-500 text-white' : 'text-gray-400 hover:text-white'
                      }`}
                    >
                      Sort by Execution Order
                    </button>
                  </div>
                </div>

                <div className="overflow-x-auto">
                  <table className="w-full text-left">
                    <thead>
                      <tr className="border-b border-gray-700">
                        <th className="py-3 px-4 text-gray-400 font-medium">ID</th>
                        <th className="py-3 px-4 text-gray-400 font-medium">Scenario</th>
                        <th className="py-3 px-4 text-gray-400 font-medium">Level</th>
                        <th className="py-3 px-4 text-gray-400 font-medium">Type</th>
                        <th className="py-3 px-4 text-gray-400 font-medium">Technique</th>
                        <th className="py-3 px-4 text-gray-400 font-medium">Priority</th>
                        <th className="py-3 px-4 text-gray-400 font-medium">Risk</th>
                        <th className="py-3 px-4 text-gray-400 font-medium">Order</th>
                        <th className="py-3 px-4 text-gray-400 font-medium">Requirements</th>
                      </tr>
                    </thead>
                    <tbody>
                      {sortedScenarios.map((scenario, index) => (
                        <tr 
                          key={`${scenario["Scenario ID"]}-${scenario["Requirements ID"][0]}`}
                          className={`border-b border-gray-800/50 ${
                            index % 2 === 0 ? 'bg-black/20' : ''
                          }`}
                        >
                          <td className="py-4 px-4 text-gray-300">{scenario["Scenario ID"]}</td>
                          <td className="py-4 px-4 text-gray-300">{scenario["Test Scenario"]}</td>
                          <td className="py-4 px-4 text-gray-300">{scenario["Test Level"]}</td>
                          <td className="py-4 px-4 text-gray-300">{scenario["Test Type"]}</td>
                          <td className="py-4 px-4 text-gray-300">{scenario["Test Design Technique"]}</td>
                          <td className="py-4 px-4">
                            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                              scenario["Priority"] === 'High' 
                                ? 'bg-red-400/10 text-red-400'
                                : scenario["Priority"] === 'Medium'
                                  ? 'bg-yellow-400/10 text-yellow-400'
                                  : 'bg-green-400/10 text-green-400'
                            }`}>
                              {scenario["Priority"]}
                            </span>
                          </td>
                          <td className="py-4 px-4">
                            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                              scenario["Risk"] === 'High' 
                                ? 'bg-red-400/10 text-red-400'
                                : scenario["Risk"] === 'Medium'
                                  ? 'bg-yellow-400/10 text-yellow-400'
                                  : 'bg-green-400/10 text-green-400'
                            }`}>
                              {scenario["Risk"]}
                            </span>
                          </td>
                          <td className="py-4 px-4 text-gray-300">{scenario["Execution Order"]}</td>
                          <td className="py-4 px-4">
                            {scenario["Requirements ID"].map((reqId: string) => (
                              <span
                                key={reqId}
                                className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-400/10 text-blue-400 mr-1"
                              >
                                {reqId}
                              </span>
                            ))}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TestScenarioDesign;

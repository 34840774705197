import React, { useState, useEffect } from 'react';
import { Outlet, Link, useLocation } from 'react-router-dom';
import TreeView from './TreeView';
import { FileSystemService } from '../services/fileSystemService';
import { TreeNode } from '../services/fileSystemService';
import logo from './veloai-logo.png';
import UserCreditsDisplay from './UserCreditsDisplay';

export interface OutletContextType {
  selectedNodes: TreeNode[];
  fileSystem: FileSystemService;
  selectedFile: TreeNode | null;
}

const Layout: React.FC = () => {
  const [fileSystem] = useState(FileSystemService.getInstance());
  const [selectedFile, setSelectedFile] = useState<TreeNode | null>(null);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [selectedNodes, setSelectedNodes] = useState<TreeNode[]>([]);
  const location = useLocation();

  const navigation = [
    { name: 'Requirements Validator', href: '/requirements-validator' },
    { name: 'Test Scenario Design', href: '/test-scenario-design' },
    { name: 'Test Case Creation', href: '/test-case-creation' },
    { name: 'Automation Development', href: '/automation-development' },
    { name: 'Coverage Optimisation', href: '/coverage-optimisation' },
    { name: 'Unit Test Generator', href: '/unit-test-generator' },
    { name: 'Unit Test Coverage Analyser', href: '/unit-test-coverage-analyzer' }
  ];

  const handleFileSelect = (node: TreeNode) => {
    setSelectedFile(node);
  };

  const handleSelectionChange = (nodes: TreeNode[]) => {
    setSelectedNodes(nodes);
  };

  // Refresh tree when file system changes
  const handleRefresh = () => {
    setRefreshCounter(prev => prev + 1);
  };

  useEffect(() => {
    // Subscribe to file system changes
    fileSystem.subscribe(handleRefresh);
    return () => {
      fileSystem.unsubscribe(handleRefresh);
    };
  }, [fileSystem]);

  return (
    <div className="min-h-screen h-screen flex flex-col bg-gradient-to-br from-gray-900 via-black to-black">
      {/* Main Navigation Bar */}
      <nav className="bg-black/30 backdrop-blur-md border-b border-neutral-800/50 flex-none">
        <div className="max-w-[1920px] mx-auto px-4 lg:px-8">
          <div className="flex h-20 items-center justify-between">
            {/* Left side with logo and navigation */}
            <div className="flex items-center flex-1">
              <Link to="/" className="flex-shrink-0">
                <img className="h-10 w-auto" src={logo} alt="VeloAI" />
              </Link>
              <div className="hidden lg:flex lg:ml-10 lg:space-x-8">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={`${
                      location.pathname === item.href
                        ? 'text-white border-b-2 border-velo-orange-500'
                        : 'text-gray-300 hover:text-white hover:border-b-2 hover:border-velo-orange-400/50'
                    } inline-flex items-center px-1 py-2 text-sm font-medium transition-all duration-200`}
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>

            {/* Right side items */}
            <div className="flex items-center space-x-6">
              <UserCreditsDisplay />
              <Link
                to="/global-context"
                className="flex items-center space-x-2 px-4 py-2 rounded-lg text-gray-300 hover:text-white bg-white/5 hover:bg-white/10 backdrop-blur-lg transition-all duration-200"
              >
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
                <span className="text-sm font-medium">Add Context</span>
              </Link>
            </div>
          </div>

          {/* Mobile Navigation Menu (hidden on desktop) */}
          <div className="lg:hidden border-t border-neutral-800/50">
            <div className="px-2 py-3 space-y-1">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className={`${
                    location.pathname === item.href
                      ? 'bg-velo-orange-500/20 text-white'
                      : 'text-gray-300 hover:bg-velo-orange-500/10 hover:text-white'
                  } block px-3 py-2 rounded-md text-base font-medium transition-colors duration-200`}
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </nav>

      {/* Main Content Area with TreeView */}
      <div className="flex flex-1 overflow-hidden">
        {/* Left Sidebar with TreeView */}
        <div className="w-80 bg-black/30 backdrop-blur-md border-r border-neutral-800/50 flex flex-col overflow-hidden">
          <div className="flex-1 overflow-auto">
            <TreeView 
              data={fileSystem.getFileSystem()} 
              onFileSelect={handleFileSelect} 
              refreshTrigger={refreshCounter}
              onSelectionChange={handleSelectionChange}
              initialSelectedNodes={new Set(selectedNodes.map(node => node.id))}
            />
          </div>
        </div>

        {/* Main Content */}
        <div className="flex-1 overflow-auto">
          <Outlet context={{ selectedNodes, fileSystem, selectedFile }} />
        </div>
      </div>
    </div>
  );
};

export default Layout;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import logo from '../components/veloai-logo.png';

const Login: React.FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const { login } = useAuth();
    const navigate = useNavigate();

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            await login(email, password);
            navigate('/');
        } catch (err) {
            setError('Invalid email or password');
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-velo-blue-900/90 via-black to-black py-12 px-4 sm:px-6 lg:px-8">
            <div className="w-full max-w-md space-y-10">
                {/* Logo Section */}
                <div className="flex flex-col items-center">
                    <img
                        className="h-20 w-auto mb-8 drop-shadow-2xl"
                        src={logo}
                        alt="VeloAI"
                    />
                    <div className="text-center">
                        <h2 className="text-3xl font-bold tracking-tight text-white">
                            Welcome back
                        </h2>
                        <p className="mt-2 text-sm text-gray-400">
                            Sign in to your account to continue
                        </p>
                    </div>
                </div>

                {/* Login Form */}
                <div className="backdrop-blur-xl bg-black/30 rounded-2xl p-8 shadow-2xl border border-white/5">
                    {error && (
                        <div className="mb-6 rounded-lg bg-red-500/10 backdrop-blur-sm border border-red-500/20 p-4">
                            <div className="flex items-center">
                                <div className="flex-shrink-0">
                                    <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                                    </svg>
                                </div>
                                <div className="ml-3">
                                    <p className="text-sm text-red-400">{error}</p>
                                </div>
                            </div>
                        </div>
                    )}

                    <form className="space-y-6" onSubmit={handleSubmit}>
                        <div className="space-y-5">
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium text-gray-300 mb-2">
                                    Email address
                                </label>
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    className="block w-full rounded-lg px-4 py-3 bg-black/40 border border-white/10 text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-velo-blue-500 focus:border-transparent transition-all duration-200 text-sm"
                                    placeholder="Enter your email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>

                            <div>
                                <label htmlFor="password" className="block text-sm font-medium text-gray-300 mb-2">
                                    Password
                                </label>
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    autoComplete="current-password"
                                    required
                                    className="block w-full rounded-lg px-4 py-3 bg-black/40 border border-white/10 text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-velo-blue-500 focus:border-transparent transition-all duration-200 text-sm"
                                    placeholder="Enter your password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                        </div>

                        <div>
                            <button
                                type="submit"
                                className="w-full flex justify-center py-3 px-4 rounded-lg text-sm font-semibold text-white bg-gradient-to-r from-velo-orange-600 to-velo-orange-500 hover:from-velo-orange-500 hover:to-velo-orange-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-velo-orange-500 focus:ring-offset-black/30 transform hover:scale-[1.02] transition-all duration-200 shadow-lg shadow-velo-orange-500/25"
                            >
                                Sign in to VeloAI
                            </button>
                        </div>
                    </form>
                </div>

                {/* Footer */}
                <div className="text-center">
                    <p className="text-sm text-gray-500">
                        Need help?{' '}
                        <a href="#" className="font-medium text-velo-blue-400 hover:text-velo-blue-300 transition-colors duration-200">
                            Contact support
                        </a>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Login;
import axios from 'axios';
import { REQUIREMENTS_VALIDATION_PROMPT, TEST_CASES_GENERATION_PROMPT } from '../constants/systemPrompts';
import { CREATE_SCENARIO_PROMPT } from '../constants/systemPrompts';



export interface TestScenario {
  "Scenario ID": string;
  "Test Scenario": string;
  "Test Level": string;
  "Test Type": string;
  "Test Design Technique": string;
  "Priority": string;
  "Risk": string;
  "Execution Order": string;
  "Requirements ID": string[];
}

interface RequirementContext {
  additionalNotes?: string;
  diagrams?: string[];
  documents?: string[];
}

const OPENAI_API_URL = 'https://api.openai.com/v1/chat/completions';
const OPENAI_API_KEY = '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';
const gptmodel = 'gpt-4o-mini';

async function sendGPTRequest(payload: any) {
  const response = await axios.post(OPENAI_API_URL, payload, {
    headers: {
      'Authorization': `Bearer ${OPENAI_API_KEY}`,
      'Content-Type': 'application/json'
    }
  });

  if (!response.data.choices || response.data.choices.length === 0) {
    throw new Error('No choices returned from the OpenAI API');
  }

  return response.data.choices[0].message.content;
}

export async function validateRequirements(requirements: string, context?: any) {
  try {
    const payload = {
      model: gptmodel,
      messages: [
        {
          role: 'system',
          content: REQUIREMENTS_VALIDATION_PROMPT
        },
        {
          role: 'user',
          content: `Use the following relevant context, the additional notes, diagrams and documents that relate to the requirements when validating the following requirements:\n${context}\nRequirements to analyze:\n${requirements}`
        }
      ],
      temperature: 0.2,
      max_tokens: 16000,
      response_format: {
        type: 'json_schema',
        json_schema: {
          name: 'ValidationResultsSchema',
          strict: true,
          schema: {
            type: 'object',
            name: 'ValidationResults',
            properties: {
              "FinalReport": {
                "type": "object",
                "properties": {
                  "Summary": {
                    "type": "string",
                    "description": "Overall summary of the validation process and key findings."
                  },
                  "Recommendations": {
                    "type": "array",
                    "items": {
                      "type": "string",
                      "description": "Recommendations for improving requirements based on validation findings."
                    }
                  },
                  "ValidatedRequirements": {
                    "type": "array",
                    "items": {
                      "type": "object",
                      "properties": {
                        "RequirementID": {
                          "type": "string",
                          "description": "Unique identifier for the validated requirement."
                        },
                        "Status": {
                          "type": "string",
                          "enum": ["Validated", "Validated with Recommendations", "Incomplete"],
                          "description": "Validation status of the requirement."
                        },
                        "Issues": {
                          "type": "array",
                          "items": {
                            "type": "string",
                            "description": "List of issues identified with the requirement."
                          }
                        },
                        "Recommendations": {
                          "type": "array",
                          "items": {
                            "type": "string",
                            "description": "Specific recommendations for this requirement."
                          }
                        }
                      },
                      "required": ["RequirementID", "Status", "Issues", "Recommendations"],
                      "additionalProperties": false
                    }
                  }
                },
                "required": ["Summary", "Recommendations", "ValidatedRequirements"],
                "additionalProperties": false
              }
            },
            "required": [
              "FinalReport",
            ],
            "additionalProperties": false
          }

        }
      }
    };

    const content = await sendGPTRequest(payload);
    return JSON.parse(content);
  } catch (error) {
    throw error;
  }
}

export async function rewriteRequirements(requirements: string, analysisResult: any) {
  try {
    const payload = {
      model: gptmodel,
      messages: [
        {
          role: 'system',
          content: "You are a requirements improvement expert. Use the given analysis to rewrite the requirements to make it fully complete, buildable, and testable without any ambiguities or missing elements."
        },
        {
          role: 'user',
          content: `Rewrite the requirement to incorporate all the analysis done so that the requirements are now fully buildable and testable. The requirements must be so comprehensive that, upon requirement analysis, no further improvements are found.\n\nAnalysis: ${JSON.stringify(analysisResult)}\n\nOriginal Requirement: ${requirements}. Please return only the rewritten requirements in JSON format.`
        }
      ],
      temperature: 0.2,
      max_tokens: 16000,
      response_format: {
        type: 'json_schema',
        json_schema: {
          name: 'RewrittenRequirementsSchema',
          strict: true,
          schema: {
            type: 'object',
            properties: {
              requirements: {
                type: 'array',
                items: {
                  type: 'object',
                  properties: {
                    id: { type: 'string' },
                    description: { type: 'string' },
                    acceptanceCriteria: {
                      type: 'array',
                      items: {
                        type: 'object',
                        properties: {
                          criterion: { type: 'string' }
                        },
                        required: ['criterion'],
                        additionalProperties: false
                      }
                    }
                  },
                  required: ['id', 'description', 'acceptanceCriteria'],
                  additionalProperties: false
                }
              }
            },
            required: ['requirements'],
            additionalProperties: false
          }
        }
      }
    };

    const content = await sendGPTRequest(payload);
    return JSON.parse(content);
  } catch (error) {
    throw error;
  }
}


interface Requirement {
  id: string;
  description: string;
  acceptanceCriteria: string;
}

export async function generateTestScenarios(requirements: string, globalContext: string, requirementIds: string[] = []): Promise<{ scenarios: TestScenario[] }> {
  try {
    // Split requirements into individual requirements
    const requirementsList = (() => {
      // Function to generate sequential requirement ID
      const generateReqId = (index: number) => `REQ-${index + 1}`;

      // Split by sections that start with the word "requirement" (case insensitive)
      const reqSections = requirements.split(/(?=\b[Rr]equirement\b)/);
      
      // If we have structured requirements (sections starting with "requirement")
      if (reqSections.length > 1) {
        return reqSections
          .map((req, index) => {
            if (!req.trim()) return null;
            
            // Try to find any ID after the word "requirement"
            const idMatch = req.match(/\b[Rr]equirement\b[:\s]+([^\n]+)/);
            const id = idMatch ? idMatch[1].trim() : generateReqId(index);
            
            // Extract description and acceptance criteria if they exist
            const descMatch = req.match(/Description:\n([\s\S]*?)(?=\n\nAcceptance Criteria:|$)/);
            const acMatch = req.match(/Acceptance Criteria:\n([\s\S]*?)(?=\n\n|$)/);
            
            // If no structured description is found, use everything after the ID line
            let description = descMatch ? descMatch[1].trim() : '';
            if (!description) {
              const reqContent = req.replace(/\b[Rr]equirement\b[:\s]+[^\n]+/, '').trim();
              description = reqContent || req.trim();
            }
            
            return {
              id,
              description,
              acceptanceCriteria: acMatch ? acMatch[1].trim() : ''
            };
          })
          .filter((req): req is Requirement => req !== null);
      }

      // Handle unstructured text by splitting on double newlines
      const unstructuredReqs = requirements
        .split(/\n\s*\n/)
        .filter(text => text.trim().length > 0);
      
      return unstructuredReqs.map((text, index) => ({
        id: generateReqId(index),
        description: text.trim(),
        acceptanceCriteria: ''
      }));
    })();

    // Generate scenarios for each requirement
    const allScenarios = [];
    for (const req of requirementsList) {
      const reqContent = `
Requirement ID: ${req.id}

Description:
${req.description}

Acceptance Criteria:
${req.acceptanceCriteria}`;

      const payload = {
        model: gptmodel,
        messages: [
          {
            role: 'system',
            content: CREATE_SCENARIO_PROMPT
          },
          {
            role: 'user',
            content: `Generate comprehensive test scenarios for the following requirement based on ISTQB and TMAP methodologies. Focus ONLY on this specific requirement:\n\n${reqContent}\n\nContext:\n${globalContext}\n\nEnsure complete coverage of the requirement and its acceptance criteria.`
          }
        ],
        temperature: 0.3,
        max_tokens: 16000,
        response_format: {
          type: 'json_schema',
          json_schema: {
            name: 'TestScenariosSchema',
            strict: true,
            schema: {
              type: 'object',
              properties: {
                scenarios: {
                  type: 'array',
                  items: {
                    type: 'object',
                    properties: {
                      "Scenario ID": { type: 'string' },
                      "Test Scenario": { type: 'string' },
                      "Test Level": { type: 'string' },
                      "Test Type": { type: 'string' },
                      "Test Design Technique": { type: 'string' },
                      "Priority": { type: 'string' },
                      "Risk": { type: 'string' },
                      "Execution Order": { type: 'number' },
                      "Requirements ID": { 
                        type: 'array',
                        items: { type: 'string' }
                      }
                    },
                    required: ['Scenario ID', 'Test Scenario', 'Test Level', 'Test Type', 'Test Design Technique', 'Priority', 'Risk', 'Execution Order', 'Requirements ID'],
                    additionalProperties: false
                  }
                }
              },
              required: ['scenarios'],
              additionalProperties: false
            }
          }
        }
      };

      const content = await sendGPTRequest(payload);
      const result = JSON.parse(content);
      
      // Ensure each scenario is linked to this requirement and has a unique ID
      const scenariosWithReqId = result.scenarios.map((scenario: any) => ({
        ...scenario,
        "Scenario ID": `${req.id}-${scenario["Scenario ID"]}`,
        "Requirements ID": [req.id]
      }));
      
      allScenarios.push(...scenariosWithReqId);
    }

    // Sort scenarios by execution order
    return { 
      scenarios: allScenarios.sort((a, b) => Number(a["Execution Order"]) - Number(b["Execution Order"])) 
    };
  } catch (error) {
    throw error;
  }
}

export async function generateTestCases(scenarios: string, globalContext: string): Promise<any[]> {
  try {
    const payload = {
      model: gptmodel,
      messages: [
        {
          role: 'system',
          content: TEST_CASES_GENERATION_PROMPT
        },
        {
          role: 'user',
          content: `Generate an exhaustive list of BDD test cases for the following scenarios based on ISTQB and TMAP methodologies. Ensure that the test cases cover all aspects of the scenarios, including but not limited to security, integration, error handling, performance, and compliance with specifications. Apply various test design techniques such as Equivalence Partitioning, Boundary Value Analysis, and Decision Table Testing to ensure thorough coverage. Incorporate risk-based testing principles to prioritize high-risk areas. Use the following context when generating the test cases:\n\n${globalContext}. Here are the scenarios:\n\n${scenarios}.`
        }
      ],
      temperature: 0.3,
      max_tokens: 16000,
      response_format: {
        type: 'json_schema',
        json_schema: {
          name: 'TestCasesSchema',
          strict: true,
          schema: {
            type: 'object',
            properties: {
              "TestCases": {
                "type": "array",
                "items": {
                  "type": "object",
                  "properties": {
                    "TestCaseID": {
                      "type": "string",
                      "description": "A unique identifier for the test case (e.g., TC-001)."
                    },
                    "TestScenarioID": {
                      "type": "string",
                      "description": "The identifier of the related test scenario (e.g., TS-001)."
                    },
                    "TestCaseDescription": {
                      "type": "string",
                      "description": "A clear and concise description of what the test case is verifying."
                    },
                    "Preconditions": {
                      "type": "string",
                      "description": "Any prerequisites or setup required before executing the test case."
                    },
                    "TestSteps": {
                      "type": "array",
                      "items": {
                        "type": "string",
                        "description": "A step-by-step list of actions to perform the test."
                      },
                      "description": "A list of steps to execute the test case."
                    },
                    "TestData": {
                      "type": ["string", "object", "array"],
                      "description": "Specific data inputs needed for the test steps. Use 'N/A' if not applicable.",
                      "items": {
                        "type": ["string", "object"]
                      }
                    },
                    "ExpectedResult": {
                      "type": "string",
                      "description": "The expected outcome after executing the test steps."
                    },
                    "ActualResult": {
                      "type": "string",
                      "description": "To be filled during test execution."
                    },
                    "Status": {
                      "type": "string",
                      "enum": ["Pass", "Fail", "Blocked", "Not Executed"],
                      "description": "To be updated after test execution."
                    },
                    "Comments": {
                      "type": "string",
                      "description": "Any additional notes or observations."
                    }
                  },
                  "required": [
                    "TestCaseID",
                    "TestScenarioID",
                    "TestCaseDescription",
                    "Preconditions",
                    "TestSteps",
                    "TestData",
                    "ExpectedResult",
                    "ActualResult",
                    "Status",
                    "Comments"
                  ],
                  "additionalProperties": false
                },
                "description": "An array of detailed test cases generated from test scenarios."
              }
            },
            "required": ["TestCases"],
            "additionalProperties": false
          }
        }
      }
    };

    const content = await sendGPTRequest(payload);
    return JSON.parse(content);
  } catch (error) {
    throw error;
  }
}

export async function generateAutomationCode(
  testCases: string,
  domStructure?: string,
  language: string = 'java',
  framework: string = 'selenium'
): Promise<any> {
  try {
    const prompt = domStructure 
      ? `Generate end-to-end ${framework} automation code based on the given test cases and DOM structure. Use the actual page elements and their locators from the DOM structure. The code should follow the Page Object Model (POM) structure and be suitable for a ${language} ${framework} framework. Include proper page transitions and verifications for each step.\n\nDOM Structure:\n${domStructure}\n\nTest Cases:\n${testCases}`
      : `Generate end-to-end ${framework} automation code based on the given test cases. The code should follow the Page Object Model (POM) structure and be suitable for a ${language} ${framework} framework.\n\nTest Cases:\n${testCases}`;

    const payload = {
      model: gptmodel,
      messages: [
        {
          role: 'system',
          content: `You are an expert automation developer proficient in multiple programming languages and testing frameworks. Generate precise automation code using actual page elements when available.`
        },
        {
          role: 'user',
          content: prompt
        }
      ],
      temperature: 0.3,
      max_tokens: 16000,
      response_format: {
        type: 'json_schema',
        json_schema: {
          name: 'AutomationCodeSchema',
          strict: true,
          schema: {
            type: 'object',
            properties: {
              featureFile: { type: 'string' },
              stepDefinitions: { type: 'string' },
              pageObjects: { type: 'string' }
            },
            required: ['featureFile', 'stepDefinitions', 'pageObjects'],
            additionalProperties: false
          }
        }
      }
    };

    const content = await sendGPTRequest(payload);
    return JSON.parse(content);
  } catch (error) {
    throw error;
  }
}

export async function analyzeCoverage(requirements: string, existingScenarios: string): Promise<any> {
  try {
    const payload = {
      model: gptmodel,
      messages: [
        {
          role: 'system',
          content: `You are an expert test coverage analyst. Analyze the given requirements and existing test scenarios to determine the current test coverage by measuring the existing scenarios. Then, suggest additional test scenarios to improve coverage. Return the results in JSON format.`
        },
        {
          role: 'user',
          content: `Analyze coverage for the following:\n\nRequirements:\n${requirements}\n\nExisting Test Scenarios:\n${existingScenarios}`
        }
      ],
      temperature: 0.3,
      max_tokens: 16000,
      response_format: {
        type: 'json_schema',
        json_schema: {
          name: 'CoverageAnalysisSchema',
          strict: true,
          schema: {
            type: 'object',
            properties: {
              currentCoverage: { type: 'number' },
              suggestedScenarios: { type: 'array', items: { type: 'string' } }
            },
            required: ['currentCoverage', 'suggestedScenarios'],
            additionalProperties: false
          }
        }
      }
    };

    const content = await sendGPTRequest(payload);
    return JSON.parse(content);
  } catch (error) {
    throw error;
  }
}

export interface UnitTestResult {
  tests: string;
}

export async function generateUnitTests(sourceCode: string, requirements: string, testScenarios: string, language: string, framework: string): Promise<UnitTestResult> {
  try {
    // First, generate exhaustive unit tests
    const exhaustiveResponse = await axios.post(
      OPENAI_API_URL,
      {
        model: gptmodel, // or 'gpt-4-0613' if you have access
        messages: [
          {
            role: 'system',
            content: `You are an expert unit test developer proficient in multiple programming languages and testing frameworks. Generate comprehensive and exhaustive unit tests for the given source code, based on the provided requirements. Use the specified programming language and test framework. Focus on testing edge cases, boundary conditions, and both positive and negative scenarios. Ensure the tests are well-structured and follow best practices for the chosen framework and language.`
          },
          {
            role: 'user',
            content: `Generate exhaustive unit tests for the following:

            Language: ${language}
            Test Framework: ${framework}
            
            Source Code:
            ${sourceCode}
            
            Requirements:
            ${requirements}
            
            Please provide only the unit test code.`
          }
        ],
        temperature: 0.3,
        max_tokens: 16000
      },
      {
        headers: {
          'Authorization': `Bearer ${OPENAI_API_KEY}`,
          'Content-Type': 'application/json'
        }
      }
    );

    let generatedTests = exhaustiveResponse.data.choices[0].message.content.trim();
    let generatedTestsnew = "";

    // If user-provided test scenarios exist, ensure they are included
    if (testScenarios.trim()) {
      const scenarioResponse = await axios.post(
        OPENAI_API_URL,
        {
          model: gptmodel,
          messages: [
            {
              role: 'system',
              content: `You are an expert unit test developer. Your task is to analyze the given unit tests and ensure that specific test scenarios are covered. If any scenarios are not covered, add the necessary unit tests to cover them.`
            },
            {
              role: 'user',
              content: `Analyze the following unit tests and ensure that the provided test scenarios are covered. If any scenarios are not covered, add the necessary unit tests.

              Language: ${language}
              Test Framework: ${framework}
　　 　 　 　
              Existing Unit Tests:
              ${generatedTests}
　　 　 　 　
              Test Scenarios to Cover:
              ${testScenarios}
　　 　 　 　
              Please provide the complete set of unit tests, including any additions to cover the specified scenarios.`
            }
          ],
          temperature: 0.3,
          max_tokens: 16000
        },
        {
          headers: {
            'Authorization': `Bearer ${OPENAI_API_KEY}`,
            'Content-Type': 'application/json'
          }
        }
      );

      generatedTestsnew = scenarioResponse.data.choices[0].message.content.trim();
    }

    return {
      tests: generatedTests + generatedTestsnew
    };
  } catch (error) {
    throw new Error('Failed to generate unit tests. Please try again.');
  }
}

export interface CoverageAnalysisResult {
  coverage: number;
  missingScenarios: string[];
}

export async function analyzeUnitCoverage(sourceCode: string, requirements: string, unitTests: string, language: string): Promise<CoverageAnalysisResult> {
  try {
    const response = await axios.post(
      OPENAI_API_URL,
      {
        model: gptmodel, // or 'gpt-4-0613' if you have access
        messages: [
          {
            role: 'system',
            content: `You are an expert in unit testing and code coverage analysis. Analyze the given source code, requirements, and existing unit tests to determine the full exhaustive test coverage against the requirements and identify missing scenarios. Provide an estimated coverage percentage and a list of scenarios that should be added to ensure full exhaustive coverage.`
          },
          {
            role: 'user',
            content: `Analyze the unit test coverage for the following:

            Language: ${language}
            
            Source Code:
            ${sourceCode}
            
            Requirements:
            ${requirements}
            
            Existing Unit Tests:
            ${unitTests}
            
            Please provide an estimated exhaustive coverage percentage and a list of missing scenarios. Format your response as follows:
            
            Coverage: [Estimated coverage percentage]
            
            Missing Scenarios:
            - [Scenario 1]
            - [Scenario 2]
            - ...`
          }
        ],
        temperature: 0.3,
        max_tokens: 16000
      },
      {
        headers: {
          'Authorization': `Bearer ${OPENAI_API_KEY}`,
          'Content-Type': 'application/json'
        }
      }
    );

    const analysisContent = response.data.choices[0].message.content.trim();
    const [coveragePart, scenariosPart] = analysisContent.split('Missing Scenarios:');
    
    const coverage = parseInt(coveragePart.split(':')[1].trim(), 10);
    const missingScenarios = scenariosPart.trim().split('\n').map((scenario: string) => scenario.replace(/^-\s*/, '').trim());

    return {
      coverage: isNaN(coverage) ? 0 : coverage,
      missingScenarios
    };
  } catch (error) {
    throw new Error('Failed to analyze coverage. Please try again.');
  }
}

// Function to get the backend URL dynamically based on the current window location
function getBackendUrl(): string {
  return process.env.VITE_BACKEND_URL || process.env.VITE_BACKEND_URL_LOCAL || 'https://msan-veloai-webapp.azurewebsites.net/';
}

export async function scrapeDOMStructure(url: string, onProgress?: (progress: string) => void): Promise<string> {
  try {
    onProgress?.('Starting DOM scraping for URL: ' + url);
    
    if (!url || typeof url !== 'string') {
      onProgress?.('❌ Error: Invalid URL provided');
      throw new Error('Invalid URL: URL must be provided as a string');
    }

    // Clean the URL (remove whitespace, etc)
    const cleanUrl = url.trim();
    if (!cleanUrl) {
      onProgress?.('❌ Error: URL cannot be empty');
      throw new Error('URL cannot be empty');
    }

    onProgress?.('Processing URL...');
    
    const backendUrl = getBackendUrl();
    onProgress?.('Connecting to scraping service...');
    
    try {
      const response = await axios.post(`${backendUrl}/api/scrape`, { url: cleanUrl }, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      });
      
      if (!response.data) {
        onProgress?.('❌ Error: No response received from scraping service');
        throw new Error('No response received from scraping service');
      }

      if (!response.data.domStructure) {
        onProgress?.('❌ Error: Invalid response format from scraping service');
        throw new Error('Invalid response from scraping service');
      }

      const elementCount = response.data.domStructure.length;
      onProgress?.(`✓ Found ${elementCount} interactive elements on the page`);
      onProgress?.('✓ DOM structure successfully extracted');
      
      return JSON.stringify(response.data.domStructure, null, 2);
    } catch (axiosError) {
      if (axios.isAxiosError(axiosError)) {
        if (axiosError.code === 'ECONNREFUSED') {
          onProgress?.('❌ Error: Could not connect to scraping service. Is the backend server running?');
          throw new Error('Could not connect to scraping service. Please ensure the backend server is running.');
        } else if (axiosError.response?.status === 404) {
          onProgress?.('❌ Error: Scraping service endpoint not found');
          throw new Error('Scraping service endpoint not found. Please check your backend configuration.');
        } else if (axiosError.response?.status === 500) {
          onProgress?.('❌ Error: Server error while scraping webpage');
          throw new Error(axiosError.response.data?.message || 'Server error while scraping webpage');
        }
      }
      onProgress?.('❌ Error: Failed to scrape webpage');
      throw new Error('Failed to scrape webpage. Please check the URL and try again.');
    }
  } catch (error) {
    throw error instanceof Error ? error : new Error('Failed to scrape webpage. Please check the URL and try again.');
  }
}

import React, { createContext, useContext, useState, useEffect } from 'react';
import axiosInstance from '../config/axios';

interface User {
    id: string;
    name: string;
    email: string;
    credits: number;
    role: 'super_admin' | 'admin' | 'user';
    company: string;
    status: 'active' | 'inactive' | 'suspended';
}

interface AuthContextType {
    isAuthenticated: boolean;
    user: User | null;
    login: (email: string, password: string) => Promise<void>;
    logout: () => void;
    refreshUserData: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [user, setUser] = useState<User | null>(null);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setIsAuthenticated(true);
            // Fetch user data when token exists
            fetchUserData();
        }
    }, []);

    const fetchUserData = async () => {
        try {
            const response = await axiosInstance.get('/auth/me');
            setUser(response.data);
        } catch (error) {
            console.error('Failed to fetch user data:', error);
            logout();
        }
    };

    const login = async (email: string, password: string) => {
        try {
            const response = await axiosInstance.post('/auth/login', {
                email,
                password,
            });
            const { token } = response.data;
            localStorage.setItem('token', token);
            setIsAuthenticated(true);
            await fetchUserData();
        } catch (error) {
            throw new Error('Login failed');
        }
    };

    const register = async (name: string, email: string, password: string) => {
        try {
            const response = await axiosInstance.post('/auth/register', {
                name,
                email,
                password,
            });
            const { token } = response.data;
            localStorage.setItem('token', token);
            setIsAuthenticated(true);
            await fetchUserData();
        } catch (error) {
            throw new Error('Registration failed');
        }
    };

    const logout = () => {
        localStorage.removeItem('token');
        setIsAuthenticated(false);
        setUser(null);
    };

    return (
        <AuthContext.Provider value={{
            isAuthenticated,
            user,
            login,
            logout,
            refreshUserData: fetchUserData // Expose this to refresh credits after usage
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
}; 
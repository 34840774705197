import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { generateAutomationCode, scrapeDOMStructure } from '../services/aiService';
import logo from './veloai-logo.png';

// Define supported languages and frameworks
const languages = [
  { value: 'java', label: 'Java' },
  { value: 'csharp', label: 'C#' },
  { value: 'typescript', label: 'TypeScript' },
  { value: 'python', label: 'Python' }
];

const frameworks = {
  java: ['selenium', 'playwright'],
  csharp: ['selenium', 'playwright'],
  typescript: ['cypress', 'playwright'],
  python: ['selenium', 'playwright', 'robot']
};

interface AutomationCode {
  featureFile: string;
  stepDefinitions: string;
  pageObjects: string;
}

const defaultAutomationCode: AutomationCode = {
  featureFile: "No valid feature file could be generated.",
  stepDefinitions: "No valid step definitions could be generated.",
  pageObjects: "No valid page objects could be generated."
};

function AutomationDevelopment() {
  const [testCases, setTestCases] = useState('');
  const [automationCode, setAutomationCode] = useState<AutomationCode>(defaultAutomationCode);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isGenerated, setIsGenerated] = useState(false);
  const [url, setUrl] = useState('');
  const [language, setLanguage] = useState('java');
  const [framework, setFramework] = useState('selenium');
  const [scrapeProgress, setScrapeProgress] = useState<string[]>([]);

  const handleLanguageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newLanguage = e.target.value;
    setLanguage(newLanguage);
    // Set default framework for the selected language
    setFramework(frameworks[newLanguage as keyof typeof frameworks][0]);
  };

  const handleGenerateAutomationCode = async () => {
    setIsLoading(true);
    setError(null);
    try {
      let domStructure;
      if (url.trim()) {
        setScrapeProgress(['Starting DOM scraping...']);
        domStructure = await scrapeDOMStructure(url, (progress: string) => {
          setScrapeProgress(prev => [...prev, progress]);
        });
        setScrapeProgress(prev => [...prev, 'Scraping completed successfully!']);
      }
      const result = await generateAutomationCode(testCases, domStructure, language, framework);
      if (isValidAutomationCode(result)) {
        setAutomationCode(result);
        setIsGenerated(true);
      } else {
        setAutomationCode(defaultAutomationCode);
        setError('Invalid automation code generated. Please try again with different test cases.');
      }
    } catch (error) {
      setAutomationCode(defaultAutomationCode);
      setError(error instanceof Error ? error.message : 'An error occurred while generating automation code. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const isValidAutomationCode = (code: any): code is AutomationCode => {
    return (
      typeof code === 'object' &&
      typeof code.featureFile === 'string' &&
      typeof code.stepDefinitions === 'string' &&
      typeof code.pageObjects === 'string'
    );
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-velo-blue-900/90 via-black to-black">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Header Section */}
        <div className="flex items-center justify-between mb-16">
          <div className="flex items-center space-x-6">
            <img 
              src={logo} 
              alt="VeloAI Logo" 
              className="h-14 w-auto filter drop-shadow-glow" 
            />
            <div>
              <div className="text-velo-orange-500/80 text-sm font-medium mb-1">Testing Tools</div>
              <h1 className="text-4xl font-bold text-white tracking-tight">
                Automation Development
              </h1>
            </div>
          </div>
          <Link 
            to="/" 
            className="group flex items-center space-x-2 text-sm text-gray-400 hover:text-white transition-colors duration-200"
          >
            <span>Return to Dashboard</span>
            <span className="transform group-hover:translate-x-0.5 transition-transform duration-150">→</span>
          </Link>
        </div>

        {/* Main Content */}
        <div className="grid grid-cols-1 gap-8">
          {/* Target URL Section */}
          <div className="backdrop-blur-sm bg-velo-blue-900/10 rounded-2xl border border-velo-blue-500/20 overflow-hidden shadow-xl">
            <div className="px-8 py-6">
              <label className="block text-sm font-medium text-gray-200 mb-2">Target URL (Optional)</label>
              <input
                type="url"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                placeholder="Enter the website URL to analyse..."
                className="w-full bg-black/20 border border-velo-blue-500/20 rounded-lg p-3 text-white placeholder-gray-400"
              />
            </div>
          </div>

          {/* Language and Framework Selection */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Language Selection */}
            <div className="backdrop-blur-sm bg-velo-blue-900/10 rounded-2xl border border-velo-blue-500/20 overflow-hidden shadow-xl">
              <div className="px-8 py-6">
                <label className="block text-sm font-medium text-gray-200 mb-2">Programming Language</label>
                <select
                  value={language}
                  onChange={handleLanguageChange}
                  className="w-full bg-black/20 border border-velo-blue-500/20 rounded-lg p-3 text-white"
                >
                  {languages.map(lang => (
                    <option key={lang.value} value={lang.value}>{lang.label}</option>
                  ))}
                </select>
              </div>
            </div>

            {/* Framework Selection */}
            <div className="backdrop-blur-sm bg-velo-blue-900/10 rounded-2xl border border-velo-blue-500/20 overflow-hidden shadow-xl">
              <div className="px-8 py-6">
                <label className="block text-sm font-medium text-gray-200 mb-2">Automation Framework</label>
                <select
                  value={framework}
                  onChange={(e) => setFramework(e.target.value)}
                  className="w-full bg-black/20 border border-velo-blue-500/20 rounded-lg p-3 text-white"
                >
                  {frameworks[language as keyof typeof frameworks].map(fw => (
                    <option key={fw} value={fw}>{fw.charAt(0).toUpperCase() + fw.slice(1)}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          {/* Test Cases Section */}
          <div className="backdrop-blur-sm bg-velo-blue-900/10 rounded-2xl border border-velo-blue-500/20 overflow-hidden shadow-xl">
            <div className="px-8 py-6">
              <label className="block text-sm font-medium text-gray-200 mb-2">Test Cases</label>
              <textarea
                value={testCases}
                onChange={(e) => setTestCases(e.target.value)}
                placeholder="Enter your test cases here..."
                rows={10}
                className="w-full bg-black/20 border border-velo-blue-500/20 rounded-lg p-3 text-white placeholder-gray-400"
              />
            </div>
          </div>

          {/* Generate Button */}
          <div className="flex justify-center mt-8">
            <button
              onClick={handleGenerateAutomationCode}
              disabled={isLoading || !testCases.trim()}
              className={`
                group relative px-8 py-4 rounded-xl font-medium text-base
                ${isLoading 
                  ? 'bg-velo-blue-900/50 cursor-not-allowed' 
                  : 'bg-gradient-to-r from-velo-orange-500 to-velo-orange-600 hover:to-velo-orange-500'
                }
                text-white transition-all duration-300 shadow-lg hover:shadow-velo-orange-500/20
                transform hover:-translate-y-0.5
              `}
            >
              <span className="flex items-center space-x-2">
                {isLoading ? (
                  <>
                    <span className="opacity-70">Generating Code</span>
                    <span className="animate-pulse">...</span>
                  </>
                ) : (
                  <>
                    <span>Generate Automation Code</span>
                  </>
                )}
              </span>
            </button>
          </div>

          {/* Progress Display */}
          {scrapeProgress.length > 0 && (
            <div className="mt-4 p-4 bg-gray-800 rounded-lg">
              <h3 className="text-lg font-semibold text-white mb-2">Scraping Progress</h3>
              <div className="space-y-1">
                {scrapeProgress.map((progress, index) => (
                  <div key={index} className="flex items-center text-gray-300">
                    <span className="mr-2">→</span>
                    <span>{progress}</span>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Results Section */}
          {isGenerated && (
            <div className="grid grid-cols-1 gap-8">
              {/* Feature File */}
              <div className="backdrop-blur-sm bg-velo-blue-900/10 rounded-2xl border border-velo-blue-500/20 overflow-hidden shadow-xl">
                <div className="px-8 py-6">
                  <h3 className="text-lg font-medium text-white mb-4">Feature File</h3>
                  <pre className="bg-black/20 rounded-lg p-4 text-gray-300 overflow-x-auto">
                    {automationCode.featureFile}
                  </pre>
                </div>
              </div>

              {/* Step Definitions */}
              <div className="backdrop-blur-sm bg-velo-blue-900/10 rounded-2xl border border-velo-blue-500/20 overflow-hidden shadow-xl">
                <div className="px-8 py-6">
                  <h3 className="text-lg font-medium text-white mb-4">Step Definitions</h3>
                  <pre className="bg-black/20 rounded-lg p-4 text-gray-300 overflow-x-auto">
                    {automationCode.stepDefinitions}
                  </pre>
                </div>
              </div>

              {/* Page Objects */}
              <div className="backdrop-blur-sm bg-velo-blue-900/10 rounded-2xl border border-velo-blue-500/20 overflow-hidden shadow-xl">
                <div className="px-8 py-6">
                  <h3 className="text-lg font-medium text-white mb-4">Page Objects</h3>
                  <pre className="bg-black/20 rounded-lg p-4 text-gray-300 overflow-x-auto">
                    {automationCode.pageObjects}
                  </pre>
                </div>
              </div>
            </div>
          )}

          {/* Error Message */}
          {error && (
            <div className="text-red-500 text-center mt-4">
              {error}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AutomationDevelopment;

import React, { useState, createContext, useContext } from 'react';
import TextArea from '../TextArea';
import { getFileType } from '../../utils/fileTypeHelper';
import { processFile } from '../../utils/processors/fileProcessor';
import { FileSystemService, TreeNode } from '../../services/fileSystemService';

interface GlobalContextData {
  businessContext: string;
  technicalContext: string;
  constraints: string;
  assumptions: string;
  diagrams: { [key: string]: string };
  documents: { [key: string]: string };
  selectedFile: TreeNode | null;
}

export const defaultGlobalContext: GlobalContextData = {
  businessContext: '',
  technicalContext: '',
  constraints: '',
  assumptions: '',
  diagrams: {},
  documents: {},
  selectedFile: null
};

export const GlobalContext = createContext<{
  contextData: GlobalContextData;
  setContextData: (data: GlobalContextData) => void;
  fileSystem: FileSystemService;
}>({
  contextData: defaultGlobalContext,
  setContextData: () => {},
  fileSystem: FileSystemService.getInstance()
});

export function GlobalContextProvider({ children }: { children: React.ReactNode }) {
  const [contextData, setContextData] = useState<GlobalContextData>(defaultGlobalContext);
  const fileSystem = FileSystemService.getInstance();

  return (
    <GlobalContext.Provider value={{ contextData, setContextData, fileSystem }}>
      {children}
    </GlobalContext.Provider>
  );
}

function GlobalContextConfig() {
  const { contextData: globalContextData, setContextData: setGlobalContextData, fileSystem } = useContext(GlobalContext);
  const [localContextData, setLocalContextData] = useState<GlobalContextData>(globalContextData);
  const [isSaving, setIsSaving] = useState(false);
  const [saveStatus, setSaveStatus] = useState<'idle' | 'success' | 'error'>('idle');

  const handleSave = () => {
    setIsSaving(true);
    setSaveStatus('idle');
    
    try {
      setGlobalContextData(localContextData);
      setSaveStatus('success');
      setTimeout(() => setSaveStatus('idle'), 3000);
    } catch (error) {
      setSaveStatus('error');
    } finally {
      setIsSaving(false);
    }
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>, type: 'diagrams' | 'documents') => {
    const files = Array.from(event.target.files || []);
    
    // Process each file
    const processedFiles = await Promise.all(files.map(async (file) => {
      const fileType = getFileType(file.type, file.name);
      try {
        const processedContent = await processFile({
          file,
          fileName: file.name,
          fileType: fileType
        });
        return { name: file.name, content: processedContent };
      } catch (error) {
        console.error(`Error processing file ${file.name}:`, error);
        return { name: file.name, content: '' };
      }
    }));

    setLocalContextData(prev => ({

      ...prev,
      [type]: {
        ...prev[type],
        ...Object.fromEntries(processedFiles.map(pf => [pf.name, pf.content]))
      }
    }));
  };

  const removeFile = (type: 'diagrams' | 'documents', fileName: string) => {
    setLocalContextData(prev => {
      const { [fileName]: removed, ...remaining } = prev[type];
      return {
        ...prev,
        [type]: remaining
      };
    });
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-velo-blue-900/90 via-black to-black">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h1 className="text-4xl font-bold text-white tracking-tight mb-8">Global Context Configuration</h1>
        
        <div className="grid grid-cols-1 gap-8">
          {/* Business Context */}
          <div className="backdrop-blur-sm bg-velo-blue-900/10 rounded-2xl border border-velo-blue-500/20 overflow-hidden shadow-xl">
            <div className="px-8 py-6">
              <TextArea
                label="Business Context"
                value={localContextData.businessContext}
                onChange={(e) => setLocalContextData(prev => ({ ...prev, businessContext: e.target.value }))}
                placeholder="Describe the business context, goals, and objectives..."
                rows={4}
              />
            </div>
          </div>

          {/* Technical Context */}
          <div className="backdrop-blur-sm bg-velo-blue-900/10 rounded-2xl border border-velo-blue-500/20 overflow-hidden shadow-xl">
            <div className="px-8 py-6">
              <TextArea
                label="Technical Context"
                value={localContextData.technicalContext}
                onChange={(e) => setLocalContextData(prev => ({ ...prev, technicalContext: e.target.value }))}
                placeholder="Describe the technical context, architecture, and implementation details..."
                rows={4}
              />
            </div>
          </div>

          {/* Constraints & Assumptions */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="backdrop-blur-sm bg-velo-blue-900/10 rounded-2xl border border-velo-blue-500/20 overflow-hidden shadow-xl">
              <div className="px-8 py-6">
                <TextArea
                  label="Constraints"
                  value={localContextData.constraints}
                  onChange={(e) => setLocalContextData(prev => ({ ...prev, constraints: e.target.value }))}
                  placeholder="List any constraints or limitations..."
                  rows={4}
                />
              </div>
            </div>
            <div className="backdrop-blur-sm bg-velo-blue-900/10 rounded-2xl border border-velo-blue-500/20 overflow-hidden shadow-xl">
              <div className="px-8 py-6">
                <TextArea
                  label="Assumptions"
                  value={localContextData.assumptions}
                  onChange={(e) => setLocalContextData(prev => ({ ...prev, assumptions: e.target.value }))}
                  placeholder="List any assumptions made..."
                  rows={4}
                />
              </div>
            </div>
          </div>

          {/* File Uploads */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Diagrams Upload */}
            <div className="backdrop-blur-sm bg-velo-blue-900/10 rounded-2xl border border-velo-blue-500/20 overflow-hidden shadow-xl">
              <div className="px-8 py-6">
                <label className="block text-velo-orange-500/80 text-sm font-medium mb-2">
                  Diagrams (UML, Flowcharts, etc.)
                </label>
                <input
                  type="file"
                  accept="image/*,.pdf"
                  multiple
                  onChange={(e) => handleFileUpload(e, 'diagrams')}
                  className="hidden"
                  id="diagram-upload"
                />
                <label
                  htmlFor="diagram-upload"
                  className="cursor-pointer inline-flex items-center px-4 py-2 border border-velo-blue-500/20 rounded-lg text-sm text-gray-300 hover:bg-velo-blue-900/30 transition-colors duration-200"
                >
                  <span>Upload Diagrams</span>
                </label>
                <div className="mt-4 space-y-2">
                  {Object.entries(localContextData.diagrams).map(([fileName, _], index) => (
                    <div key={index} className="flex items-center justify-between bg-black/20 rounded-lg p-2">
                      <span className="text-gray-300 text-sm">{fileName}</span>
                      <button
                        onClick={() => removeFile('diagrams', fileName)}
                        className="text-red-400 hover:text-red-300"
                      >
                        Remove
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* Documents Upload */}
            <div className="backdrop-blur-sm bg-velo-blue-900/10 rounded-2xl border border-velo-blue-500/20 overflow-hidden shadow-xl">
              <div className="px-8 py-6">
                <label className="block text-velo-orange-500/80 text-sm font-medium mb-2">
                  Documents
                </label>
                <input
                  type="file"
                  accept=".pdf,.doc,.docx,.txt,.md"
                  multiple
                  onChange={(e) => handleFileUpload(e, 'documents')}
                  className="hidden"
                  id="document-upload"
                />
                <label
                  htmlFor="document-upload"
                  className="cursor-pointer inline-flex items-center px-4 py-2 border border-velo-blue-500/20 rounded-lg text-sm text-gray-300 hover:bg-velo-blue-900/30 transition-colors duration-200"
                >
                  <span>Upload Documents</span>
                </label>
                <div className="mt-4 space-y-2">
                  {Object.keys(localContextData.documents).map((fileName, index) => (
                    <div key={index} className="flex items-center justify-between bg-black/20 rounded-lg p-2">
                      <span className="text-gray-300 text-sm">{fileName}</span>
                      <button
                        onClick={() => removeFile('documents', fileName)}
                        className="text-red-400 hover:text-red-300"
                      >
                        Remove
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end mt-8">
        <button
          onClick={handleSave}
          disabled={isSaving}
          className={`

            group relative px-8 py-4 rounded-xl font-medium text-base
            ${isSaving 
              ? 'bg-velo-blue-900/50 cursor-not-allowed' 
              : 'bg-gradient-to-r from-velo-orange-500 to-velo-orange-600 hover:to-velo-orange-500'
            }
            text-white transition-all duration-300 shadow-lg hover:shadow-velo-orange-500/20
            transform hover:-translate-y-0.5
          `}
        >
          <span className="flex items-center space-x-2">
            {isSaving ? (
              <>

                <span className="opacity-70">Saving...</span>
                <span className="animate-pulse">...</span>
              </>

            ) : (
              <>

                <span>Save Global Context</span>
                <span className="transform group-hover:translate-x-0.5 transition-transform duration-150">
                  →
                </span>
              </>

            )}
          </span>
        </button>
        
        {saveStatus === 'success' && (
          <div className="fixed bottom-8 right-8 bg-green-500/90 text-white px-6 py-3 rounded-lg shadow-lg">
            Global context saved successfully!
          </div>
        )}
        
        {saveStatus === 'error' && (
          <div className="fixed bottom-8 right-8 bg-red-500/90 text-white px-6 py-3 rounded-lg shadow-lg">
            Error saving global context. Please try again.
          </div>
        )}
      </div>
    </div>
  );
}

export default GlobalContextConfig;
export interface TreeNode {
  id: string;
  name: string;
  type: 'file' | 'folder';
  children?: TreeNode[];
  content?: any;
  componentType?: string;
}

export class FileSystemService {
  private static instance: FileSystemService;
  private fileSystem: TreeNode;
  private listeners: (() => void)[] = [];

  private constructor() {
    // Initialize root structure
    this.fileSystem = {
      id: 'root',
      name: 'Project',
      type: 'folder',
      children: [
        {
          id: 'requirements',
          name: 'Requirements',
          type: 'folder',
          children: []
        },
        {
          id: 'scenarios',
          name: 'Test Scenarios',
          type: 'folder',
          children: []
        },
        {
          id: 'testcases',
          name: 'Test Cases',
          type: 'folder',
          children: []
        },
        {
          id: 'automation',
          name: 'Automation Code',
          type: 'folder',
          children: []
        }
      ]
    };
  }

  public static getInstance(): FileSystemService {
    if (!FileSystemService.instance) {
      FileSystemService.instance = new FileSystemService();
    }
    return FileSystemService.instance;
  }

  public getFileSystem(): TreeNode {
    return this.fileSystem;
  }

  public subscribe(listener: () => void): void {
    this.listeners.push(listener);
  }

  public unsubscribe(listener: () => void): void {
    this.listeners = this.listeners.filter(l => l !== listener);
  }

  private notifyListeners(): void {
    this.listeners.forEach(listener => listener());
  }

  public saveFile(path: string[], content: any, componentType: string): void {
    let current = this.fileSystem;
    
    // Navigate to the correct folder
    for (let i = 0; i < path.length - 1; i++) {
      const folder = current.children?.find(child => child.name === path[i]);
      if (!folder) {
        throw new Error(`Folder ${path[i]} not found`);
      }
      current = folder;
    }

    // Create new file
    const fileName = path[path.length - 1];
    const newFile: TreeNode = {
      id: `${Date.now()}`,
      name: fileName,
      type: 'file',
      content,
      componentType
    };

    if (!current.children) {
      current.children = [];
    }
    current.children.push(newFile);
    this.notifyListeners();
  }

  public createFolder(path: string[]): void {
    let current = this.fileSystem;
    
    for (const folderName of path) {
      let folder = current.children?.find(child => child.name === folderName);
      
      if (!folder) {
        folder = {
          id: `${Date.now()}`,
          name: folderName,
          type: 'folder',
          children: []
        };
        
        if (!current.children) {
          current.children = [];
        }
        current.children.push(folder);
      }
      
      current = folder;
    }
    this.notifyListeners();
  }

  public getFile(path: string[]): TreeNode | null {
    let current = this.fileSystem;
    
    for (const segment of path) {
      const node = current.children?.find(child => child.name === segment);
      if (!node) {
        return null;
      }
      current = node;
    }
    
    return current;
  }

  public updateRequirements(requirements: any[]): void {
    // Find the Requirements folder
    const requirementsFolder = this.fileSystem.children?.find(child => child.name === 'Requirements');
    if (!requirementsFolder) {
      throw new Error('Requirements folder not found');
    }

    // Create a new timestamp folder
    const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
    const timestampFolder: TreeNode = {
      id: `timestamp-${Date.now()}`,
      name: timestamp,
      type: 'folder',
      children: []
    };

    // Add requirements to the timestamp folder
    requirements.forEach((req, index) => {
      const reqFolder: TreeNode = {
        id: `req-${Date.now()}-${index}`,
        name: `Requirement ${index + 1}`,
        type: 'folder',
        children: [
          {
            id: `desc-${Date.now()}-${index}`,
            name: 'description.txt',
            type: 'file',
            content: req.description
          }
        ]
      };

      // Add acceptance criteria if they exist
      if (req.acceptanceCriteria && req.acceptanceCriteria.length > 0) {
        req.acceptanceCriteria.forEach((criteria: string, criteriaIndex: number) => {
          reqFolder.children?.push({
            id: `ac-${Date.now()}-${index}-${criteriaIndex}`,
            name: `${criteriaIndex + 1}`,
            type: 'file',
            content: criteria
          });
        });
      }

      timestampFolder.children?.push(reqFolder);
    });

    requirementsFolder.children?.push(timestampFolder);
    this.notifyListeners();
  }
}
